import ApiService from '@/api/new/services/api.service'
import UtilsFunction from '@/utils/index'

const state = {
  tasks: {
    data: [],
    pages: {
      current: 0,
      total: 0,
      perPage: 0
    }
  },
  currentTask: {},
  currentPage: 0,
  currentConversation: {},
  taskFilters: {}
}

const mutations = {
  setTasks(state, tasks) {
    state.tasks = {
      data: tasks.data,
      pages: {
        current: tasks.meta.current_page,
        total: tasks.meta.last_page,
        perPage: tasks.meta.per_page
      }
    }
  },
  setCurrentTask(state, task) {
    state.currentTask = task
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setCurrentConversation(state, conversation) {
    state.currentConversation = conversation
  },
  setFilterStructure(state, filters) {
    const formatTitle = (type) => {
      if (type == 'account_id') return 'Client'
      const capitalize = UtilsFunction.capitalizeFirstLetter(type)
      return capitalize.replace(/_/g, ' ')
    }
    const keys = Object.keys(filters)
    state.taskFilters = keys.map((key) => {
      const data = filters[key]
      return {
        type: key,
        title: formatTitle(key),
        value: '',
        data
      }
    })
  },
  updateTaskFilter(state, { value, type }) {
    state.taskFilters.forEach((el) => {
      if (el.type === type) {
        el.value = value
      }
    })
  }
}

const actions = {
  async getTasks({ state, commit, rootGetters }, payload) {
    const { page, perPage, search } = payload
    const params = new URLSearchParams()
    if (Object.values(state.taskFilters).length > 0) {
      state.taskFilters.forEach((filter) => {
        params.append(`filters[${filter.type}][]`, filter.value)
      })
    }
    if (search) {
      params.append(`filters[query]`, search)
    }
    params.append('page', page)
    if (perPage) {
      params.append('perPage', perPage)
    }
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.get(`suppliers/${supplierId}/tasks`, { params })
      .then(async (res) => {
        commit('setTasks', res.data)
        commit('setCurrentPage', page)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getTask({ commit, dispatch, rootGetters }, taskId) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.get(`suppliers/${supplierId}/tasks/${taskId}`)
      .then(async (res) => {
        const currentTask = res.data.data
        commit('setCurrentTask', currentTask)
        if (currentTask.attributes.type === 'ai_translation')
          await dispatch(
            'supplierTranslationFile/getUploadedFile',
            currentTask.attributes.target_language,
            { root: true }
          )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async acceptTask({ dispatch, rootGetters }, taskId) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.post(`suppliers/${supplierId}/tasks/${taskId}/accept`)
      .then(async () => {
        await dispatch('getTask', taskId)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async declineTask({ dispatch, rootGetters }, taskId) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.post(`suppliers/${supplierId}/tasks/${taskId}/decline`)
      .then(async () => {
        await dispatch('getTasks', {
          page: 1,
          perPage: 15,
          search: undefined
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getTaskConversation({ commit, rootGetters }, taskId) {
    const supplierId = rootGetters['supplier/supplierId']
    ApiService.get(`suppliers/${supplierId}/tasks/${taskId}/conversations`)
      .then((res) => {
        commit('setCurrentConversation', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async writeMessage({ dispatch, rootGetters }, payload) {
    const supplierId = rootGetters['supplier/supplierId']
    const data = {
      data: {
        type: 'conversation',
        attributes: {
          conversation_id: payload.conversationId
            ? payload.conversationId
            : null,
          subject: payload.subject,
          message: payload.message
        }
      }
    }
    await ApiService.post(
      `suppliers/${supplierId}/tasks/${payload.taskId}/conversations`,
      data
    )
      .then(async () => {
        await dispatch('getTaskConversation', payload.taskId)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async readSupplierMessage(
    { dispatch, rootGetters },
    { conversationId, taskId }
  ) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.post(
      `suppliers/${supplierId}/tasks/${taskId}/conversations/${conversationId}/read`
    )
      .then(async () => {
        await dispatch('workspace/getCurrentUser', null, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateTaskFilters({ commit, rootState, dispatch }) {
    if (
      rootState['workspace'].currentUser &&
      rootState['workspace'].currentUser.attributes.task_filters
    ) {
      const filters = rootState['workspace'].currentUser.attributes.task_filters
      commit('setFilterStructure', filters)
    } else {
      await dispatch('getTaskFilters')
    }
  },
  async getTaskFilters({ commit }) {
    await ApiService.get(`user`)
      .then(async (res) => {
        const filters = res.data.data.attributes.task_filters
        commit('setFilterStructure', filters)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  pages(state) {
    return state.tasks.pages
  },
  tasks(state) {
    return state.tasks.data
  },
  taskMessages(state) {
    if (
      state.currentConversation &&
      Object.keys(state.currentConversation).length > 0
    ) {
      return state.currentConversation.attributes.messages
    }
    return []
  },
  taskType: (state) => (type) => {
    if (type === 'qa') return 'Quality assurance'
    if (type === 'translation') return 'Human translation'
    if (type === 'ai_translation') return 'HumanAI'
    if (type === 'review') return 'Review'
    return type
  }
}

export const supplierDashboard = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
